function FourFrames() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 449 451"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 75.0316C0 55.132 7.88069 36.0474 21.9084 21.9762C35.9361 7.90509 54.9618 0 74.8 0H130.9C150.738 0 169.764 7.90509 183.792 21.9762C197.819 36.0474 205.7 55.132 205.7 75.0316V131.305C205.7 151.205 197.819 170.289 183.792 184.361C169.764 198.432 150.738 206.337 130.9 206.337H74.8C54.9618 206.337 35.9361 198.432 21.9084 184.361C7.88069 170.289 0 151.205 0 131.305V75.0316ZM243.1 75.0316C243.1 55.132 250.981 36.0474 265.008 21.9762C279.036 7.90509 298.062 0 317.9 0H374C393.838 0 412.864 7.90509 426.892 21.9762C440.919 36.0474 448.8 55.132 448.8 75.0316V131.305C448.8 151.205 440.919 170.289 426.892 184.361C412.864 198.432 393.838 206.337 374 206.337H317.9C298.062 206.337 279.036 198.432 265.008 184.361C250.981 170.289 243.1 151.205 243.1 131.305V75.0316ZM0 318.884C0 298.985 7.88069 279.9 21.9084 265.829C35.9361 251.758 54.9618 243.853 74.8 243.853H130.9C150.738 243.853 169.764 251.758 183.792 265.829C197.819 279.9 205.7 298.985 205.7 318.884V375.158C205.7 395.058 197.819 414.142 183.792 428.213C169.764 442.284 150.738 450.189 130.9 450.189H74.8C54.9618 450.189 35.9361 442.284 21.9084 428.213C7.88069 414.142 0 395.058 0 375.158V318.884ZM243.1 318.884C243.1 298.985 250.981 279.9 265.008 265.829C279.036 251.758 298.062 243.853 317.9 243.853H374C393.838 243.853 412.864 251.758 426.892 265.829C440.919 279.9 448.8 298.985 448.8 318.884V375.158C448.8 395.058 440.919 414.142 426.892 428.213C412.864 442.284 393.838 450.189 374 450.189H317.9C298.062 450.189 279.036 442.284 265.008 428.213C250.981 414.142 243.1 395.058 243.1 375.158V318.884Z"
        fill="var(--color-white)"
      />
    </svg>
  );
}

export default FourFrames;
