function AddColor() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.47467 1.36316C8.31641 1.02948 9.24884 0.843994 10.23 0.843994C12.0255 0.843994 13.7475 1.55725 15.0171 2.8269C16.2867 4.0965 17 5.81848 17 7.61401C17 9.40948 16.2867 11.1315 15.0171 12.4011C13.7475 13.6707 12.0255 14.384 10.23 14.384C9.93076 14.384 9.64374 14.2651 9.43216 14.0535C9.22055 13.8419 9.10165 13.5549 9.10165 13.2557C9.10165 12.9623 9.21451 12.699 9.39502 12.5034C9.56805 12.3004 9.68088 12.037 9.68088 11.7512C9.68088 11.452 9.56201 11.165 9.3504 10.9534C9.13879 10.7418 8.85181 10.6229 8.55255 10.6229H7.2211C6.2236 10.6229 5.26694 10.2266 4.56161 9.5213C4.07214 9.0318 3.73151 8.42126 3.56885 7.76007C5.98044 7.70166 7.91748 5.72839 7.91748 3.30273C7.91748 2.6076 7.75842 1.94958 7.47467 1.36316ZM14.1473 11.2746C13.9357 11.4862 13.6487 11.6051 13.3495 11.6051C13.0502 11.6051 12.7632 11.4862 12.5516 11.2746C12.34 11.063 12.2211 10.776 12.2211 10.4767C12.2211 10.1775 12.34 9.8905 12.5516 9.67889C12.7632 9.46735 13.0502 9.34845 13.3495 9.34845C13.6487 9.34845 13.9357 9.46735 14.1473 9.67889C14.3589 9.8905 14.4778 10.1775 14.4778 10.4767C14.4778 10.776 14.3589 11.063 14.1473 11.2746ZM12.9084 4.2746C12.6968 4.48621 12.4098 4.6051 12.1106 4.6051C11.8113 4.6051 11.5243 4.48621 11.3127 4.2746C11.1011 4.06305 10.9822 3.776 10.9822 3.47675C10.9822 3.17749 11.1011 2.8905 11.3127 2.67889C11.5243 2.46735 11.8113 2.34845 12.1106 2.34845C12.4098 2.34845 12.6968 2.46735 12.9084 2.67889C13.12 2.8905 13.2389 3.17749 13.2389 3.47675C13.2389 3.776 13.12 4.06305 12.9084 4.2746ZM15.1651 7.28351C14.9535 7.49512 14.6665 7.61401 14.3672 7.61401C14.068 7.61401 13.781 7.49512 13.5694 7.28351C13.3578 7.0719 13.2389 6.78491 13.2389 6.48566C13.2389 6.1864 13.3578 5.89941 13.5694 5.68781C13.781 5.4762 14.068 5.3573 14.3672 5.3573C14.6665 5.3573 14.9535 5.4762 15.1651 5.68781C15.3767 5.89941 15.4955 6.1864 15.4955 6.48566C15.4955 6.78491 15.3767 7.0719 15.1651 7.28351Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.0577 0.471822C3.0577 0.346687 3.10865 0.226677 3.19934 0.138193C3.29004 0.0497097 3.41305 0 3.54131 0C3.66958 0 3.79259 0.0497097 3.88328 0.138193C3.97398 0.226677 4.02493 0.346687 4.02493 0.471822V2.83093H6.44302C6.57128 2.83093 6.69429 2.88064 6.78499 2.96912C6.87568 3.05761 6.92664 3.17762 6.92664 3.30275C6.92664 3.42789 6.87568 3.5479 6.78499 3.63638C6.69429 3.72487 6.57128 3.77457 6.44302 3.77457H4.02493V6.13368C4.02493 6.25882 3.97398 6.37883 3.88328 6.46731C3.79259 6.5558 3.66958 6.60551 3.54131 6.60551C3.41305 6.60551 3.29004 6.5558 3.19934 6.46731C3.10865 6.37883 3.0577 6.25882 3.0577 6.13368V3.77457H0.639608C0.511345 3.77457 0.388336 3.72487 0.29764 3.63638C0.206944 3.5479 0.155991 3.42789 0.155991 3.30275C0.155991 3.17762 0.206944 3.05761 0.29764 2.96912C0.388336 2.88064 0.511345 2.83093 0.639608 2.83093H3.0577V0.471822Z"
        fill="white"
      />
    </svg>
  );
}

export default AddColor;
