function TwoFrames() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 451 451"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9687 21.9688C7.9024 36.0351 0 55.1132 0 75.006V131.26C0 151.153 7.9024 170.231 21.9687 184.298C36.0351 198.364 55.1132 206.267 75.006 206.267H131.261C133.209 206.267 135.151 206.191 137.08 206.041H312.956C314.885 206.191 316.826 206.267 318.775 206.267H375.03C394.923 206.267 414.001 198.364 428.067 184.298C442.134 170.231 450.036 151.153 450.036 131.26V75.006C450.036 55.1132 442.134 36.0351 428.067 21.9688C414.001 7.9024 394.923 0 375.03 0H75.006C55.1132 0 36.0351 7.9024 21.9687 21.9688Z"
        fill="var(--color-white)"
      />
      <path
        d="M21.9687 265.738C7.9024 279.805 0 298.883 0 318.775V375.03C0 394.923 7.9024 414.001 21.9687 428.067C36.0351 442.134 55.1132 450.036 75.006 450.036H375.03C394.923 450.036 414.001 442.134 428.067 428.067C442.134 414.001 450.036 394.923 450.036 375.03V318.775C450.036 298.883 442.134 279.805 428.067 265.738C414.001 251.672 394.923 243.769 375.03 243.769H318.775C316.826 243.769 314.885 243.845 312.956 243.995H137.08C135.151 243.845 133.209 243.769 131.261 243.769H75.006C55.1132 243.769 36.0351 251.672 21.9687 265.738Z"
        fill="var(--color-white)"
      />
    </svg>
  );
}

export default TwoFrames;
